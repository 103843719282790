<template>
  <!-- Under maintenance-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <b-img
        :src="appLogoImage"
        width="40"
        height="40"
        alt="logo"
      />
      <h2 class="brand-text text-primary ml-1">
        {{ appName }}
      </h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Page de traitement
        </h2>

        <p class="mb-3">
          Veuillez patienter, requête en cours de traitement ⌛
        </p>

        <b-form
          inline
          class="justify-content-center row m-0 mb-2"
          @submit.prevent
        >

          <b-button
            variant="primary"
            disabled
            class="mr-1"
          >
            Loading...

            <b-spinner
              small
              type="grow"
            />
          </b-button>
        </b-form>

        <!-- img -->
        <b-img
          fluid
          :src="imgUrl"
          alt="Under maintenance page"
        />
      </div>
    </div>
  </div>
<!-- / Under maintenance-->
</template>

<script>
/* eslint-disable global-require */
import {
  BLink, BSpinner, BButton, BForm, BImg,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import store from '@/store/index'
import { $themeConfig } from '@themeConfig'
// eslint-disable-next-line import/no-cycle
import useAuth from '@/services/auth/authService'
import { onBeforeMount } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import useSignatures from '@/services/signature/signatureService'
// eslint-disable-next-line import/no-cycle
import router from '@/router'

export default {
  components: {
    BLink,
    BSpinner,
    BButton,
    BForm,
    BImg,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/under-maintenance.svg'),
    }
  },
  setup() {
    const { appName, appLogoImage } = $themeConfig.app
    const { accessToken } = useAuth()
    const { getDocumentInformation } = useSignatures()
    onBeforeMount(async () => {
      // eslint-disable-next-line no-prototype-builtins
      if (router.currentRoute.query.hasOwnProperty('code')) {
        await accessToken()
      }

      // eslint-disable-next-line no-prototype-builtins
      if (router.currentRoute.hasOwnProperty('query') && router.currentRoute.query.status === 'finished' && router.currentRoute.query.request_certificate_id) {
        await getDocumentInformation(router.currentRoute.query.signer_process_id, router.currentRoute.query.status, router.currentRoute.query.request_certificate_id)
      }
    })
    return {
      appName,
      appLogoImage,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/under-maintenance-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
